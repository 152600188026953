import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { CompetitionDetailsLineupTeamLogo_team } from './__generated__/index.graphql';

const TeamLogoImage = styled.img<{ big?: boolean }>`
  --logo-size: ${({ big }) =>
    big ? 'var(--triple-unit)' : 'var(--double-unit)'};
  height: var(--logo-size);
  width: var(--logo-size);
  align-self: center;
  @media ${tabletAndAbove} {
    --logo-size: ${({ big }) =>
      big ? 'var(--quadruple-unit)' : 'var(--double-unit)'};
  }
`;

type Props = {
  team: CompetitionDetailsLineupTeamLogo_team;
  big?: boolean;
};

export const TeamLogo = ({ team, big }: Props) => {
  const { pictureUrl, name } = team || {};
  if (!pictureUrl) return null;
  return <TeamLogoImage src={pictureUrl} alt={name} big={big} />;
};

TeamLogo.fragments = {
  team: gql`
    fragment CompetitionDetailsLineupTeamLogo_team on TeamInterface {
      slug
      name
      pictureUrl
    }
  ` as TypedDocumentNode<CompetitionDetailsLineupTeamLogo_team>,
};
