import styled from 'styled-components';

export const BigText = styled.p`
  white-space: nowrap;
  font: var(--t-20);
  color: var(--c-neutral-600);
  &.bold {
    font-weight: var(--t-bold);
    color: var(--c-neutral-1000);
  }
`;
