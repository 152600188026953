import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { generatePath } from 'react-router-dom';

import {
  GameStatus,
  ScoreStatus,
} from '@sorare/core/src/__generated__/globalTypes';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { MLB_TEAMS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { withFragments } from '@sorare/core/src/gql';
import { playerGameStatusLabels } from '@sorare/core/src/lib/glossary';

import { GenericGame } from '@sorare/gameplay/src/components/seo/Games/Game';

import {
  AnyGameToGenericGame_anyGame,
  AnyPlayerGameStatsToGenericGame_anyPlayerGameStats,
} from './__generated__/utils.graphql';

export const anyGameToGenericGame = withFragments(
  ({ game }: { game: AnyGameToGenericGame_anyGame }): GenericGame => {
    return {
      ...game,
      date: game.date,
      // Reverse team orders to comply with US standards
      awayTeam: game.homeTeam
        ? {
            ...game.homeTeam,
            to: generatePath(MLB_TEAMS_SLUG, { slug: game.homeTeam.slug }),
          }
        : null,
      homeTeam: game.awayTeam
        ? {
            ...game.awayTeam,
            to: generatePath(MLB_TEAMS_SLUG, { slug: game.awayTeam.slug }),
          }
        : null,
      status: game.statusTyped,
    };
  },
  {
    anyGame: gql`
      fragment AnyGameToGenericGame_anyGame on AnyGameInterface {
        id
        date
        statusTyped
        homeTeam {
          slug
          name
          pictureUrl
        }
        homeScore
        awayTeam {
          slug
          name
          pictureUrl
        }
        awayScore
        so5Fixture {
          slug
          gameWeek
        }
      }
    ` as TypedDocumentNode<AnyGameToGenericGame_anyGame>,
  }
);

export const anyPlayerGameStatsToGenericGame = withFragments(
  ({
    playerGameStats,
    onClick,
  }: {
    playerGameStats: AnyPlayerGameStatsToGenericGame_anyPlayerGameStats;
    onClick?: (id: string) => void;
  }): GenericGame => {
    const { anyGame: game, playerGameScore } = playerGameStats;
    const matchLiveOrPlayed = [GameStatus.played, GameStatus.playing].includes(
      game.statusTyped
    );

    const computedGameScore = playerGameScore;

    const isDnp = [ScoreStatus.DID_NOT_PLAY, ScoreStatus.NO_GAME].includes(
      computedGameScore?.scoreStatus || ScoreStatus.DID_NOT_PLAY
    );
    return {
      ...anyGameToGenericGame({ game }),
      ...(matchLiveOrPlayed
        ? {
            playerScore: (
              <BodyS as="p" bold>
                {isDnp ? (
                  <FormattedMessage
                    {...playerGameStatusLabels.did_not_play_short}
                  />
                ) : (
                  <FormattedNumber
                    value={Math.floor(computedGameScore?.score || 0)}
                    maximumFractionDigits={0}
                  />
                )}
              </BodyS>
            ),
          }
        : {}),
      onClick:
        matchLiveOrPlayed && playerGameScore
          ? () => {
              onClick?.(playerGameScore.id);
            }
          : undefined,
    };
  },
  {
    anyPlayerGameStats: gql`
      fragment AnyPlayerGameStatsToGenericGame_anyPlayerGameStats on AnyPlayerGameStatsInterface {
        id
        playerGameScore {
          id
          score
          scoreStatus
        }
        anyGame {
          id
          statusTyped
          ...AnyGameToGenericGame_anyGame
        }
      }
      ${anyGameToGenericGame.fragments.anyGame}
    ` as TypedDocumentNode<AnyPlayerGameStatsToGenericGame_anyPlayerGameStats>,
  }
);
