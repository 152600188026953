import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import IconButton from '@sorare/core/src/atoms/buttons/IconButton';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { mobileAndAbove } from '@sorare/core/src/style/mediaQuery';

import rewards from './assets/rewards.png';

const Root = styled(Horizontal).attrs({ center: true, gap: 1 })`
  width: 100%;
  background: linear-gradient(
    84.1deg,
    #f8d3da 0%,
    #b3a9f4 28.32%,
    #fbe9fb 54.01%,
    #4f94fd 100%
  );

  &.rounded {
    border-radius: var(--unit);
  }

  @media ${mobileAndAbove} {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-areas: 'gutter content button';
  }
`;

const Content = styled(Horizontal).attrs({ center: true, gap: 1 })`
  grid-area: content;
`;

const ButtonContainer = styled.div`
  grid-area: button;
`;

type Props = {
  rounded?: boolean;
};

export const FirstEditionBanner = ({ rounded }: Props) => {
  return (
    <Root className={classNames({ rounded })}>
      <Content>
        <img src={rewards} alt="Rewards" width="40px" height="20px" />
        <LabelM color="var(--c-neutral-100)" bold>
          <FormattedMessage
            id="FirstEditionBanner.winJerseyOrEssences"
            defaultMessage="Win a jersey or essences capsules"
          />
        </LabelM>
      </Content>
      <ButtonContainer>
        <IconButton
          to="https://sorare.com/blog/1st-edition-week-special-offer"
          externalLink
          small
          icon={faInfoCircle}
          color="transparent"
        />
      </ButtonContainer>
    </Root>
  );
};
