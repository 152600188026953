import { Navigate, RouteObject, useLocation } from 'react-router-dom';

import {
  FOOTBALL_PATH,
  MLB_PATH,
  NBA_PATH,
  catchAll,
} from '@sorare/core/src/constants/routes';
import useGetSplat from '@sorare/core/src/hooks/useGetSplat';
import { relative } from '@sorare/core/src/lib/routing';
import { RouteErrorBoundary } from '@sorare/core/src/routing/RouteErrorBoundary';

import { routeObjects as baseballPages } from '@sorare/baseball/src/pages';
import { routeObjects as blogPages } from '@sorare/blog/src/pages';
import { routeObjects as footballPages } from '@sorare/football/src/pages';
import { routeObjects as helpCenterPages } from '@sorare/help/src/pages';
import { routeObjects as nbaPages } from '@sorare/nba/src/pages';
import { routeObjects as sharedPages } from '@sorare/shared-pages/src/pages';
import { InnerAppLayout, OuterAppLayout } from 'AppLayout';

const RedirectToFootball = () => {
  const location = useLocation();
  const getSplat = useGetSplat();
  return (
    <Navigate to={getSplat('/*', '/football/*') + location.search} replace />
  );
};

export const routeObjects: RouteObject[] = [
  {
    element: <OuterAppLayout />,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: <InnerAppLayout />,
        children: [
          ...sharedPages,
          ...helpCenterPages,
          ...blogPages,
          {
            path: relative('/', catchAll(FOOTBALL_PATH)),
            children: footballPages,
          },
          {
            path: MLB_PATH,
            children: baseballPages,
          },
          {
            path: NBA_PATH,
            children: nbaPages,
          },
          {
            path: '/*',
            element: <RedirectToFootball />,
          },
        ],
      },
    ],
  },
];
