import {
  OperationVariables,
  PreloadQueryOptions,
  QueryReference,
  TypedDocumentNode,
  UseReadQueryResult,
  useReadQuery,
} from '@apollo/client';
import { captureMessage } from '@sentry/react';
import { ComponentType, Suspense } from 'react';
import { Params, useLoaderData } from 'react-router-dom';
import styled from 'styled-components';

import LoadingIndicator from 'atoms/loader/LoadingIndicator';
import { preloadQuery } from 'contexts/graphql/Provider';

import shouldRevalidateFn from './shouldRevalidateFn';

type QueryOptions<
  PageOrLayoutParams extends Params,
  TVariables extends OperationVariables,
> =
  Values<TVariables> extends never
    ? {
        queryOptions?: (
          args: PageOrLayoutParams,
          url: URL
        ) => PreloadQueryOptions<never>;
      }
    : {
        queryOptions: (
          args: PageOrLayoutParams,
          url: URL
        ) => PreloadQueryOptions<TVariables>;
      };

type DataFunctionValue = Response | NonNullable<unknown> | null;

type LoaderFunction<PageOrLayoutParams extends Params> = (args: {
  params: PageOrLayoutParams;
  request: Request;
}) => Promise<DataFunctionValue> | DataFunctionValue;

type UIOptions =
  | {
      // Wait for the query to finish to display the component because you need the data to render smooth animation,
      preventTransitionBeforeLoaded: true;
    }
  | {
      // Display the component as soon as possible to display skeletons instead
      preventTransitionBeforeLoaded?: false;
      // a Fallback component can be provided to display a loading state
      // Default is LoadingIndicator
      FallbackComponent?: ComponentType;
    };

const DefaultFallbackWrapper = styled.div`
  &,
  & > * {
    height: inherit;
    min-height: inherit;
  }
`;

const DefaultFallback = () => (
  <DefaultFallbackWrapper>
    <LoadingIndicator />
  </DefaultFallbackWrapper>
);
export const withRouteQuery = <
  PageOrLayoutParams extends Params,
  P,
  TData,
  TVariables extends OperationVariables,
>(
  Component: ComponentType<P & { queryResult: UseReadQueryResult<TData> }>,
  options: {
    query: TypedDocumentNode<TData, TVariables>;
  } & QueryOptions<PageOrLayoutParams, TVariables> &
    UIOptions
): ComponentType<P> & {
  loader: LoaderFunction<PageOrLayoutParams>;
} => {
  const { query, queryOptions, preventTransitionBeforeLoaded } = options;

  const loader: LoaderFunction<PageOrLayoutParams> = async ({
    params,
    request,
  }) => {
    if (!preloadQuery.current) {
      captureMessage('Apollo preloadQuery is not initialized');
      return null;
    }
    const preloadedQueryRef = preloadQuery.current(
      query,
      // this apollo version types badly PreloadQueryFunction so an {} object is needed
      // even when the query asks for no variables
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      {
        // Default fetchPolicy is 'cache-first', override to 'cache-and-network'
        fetchPolicy: 'cache-and-network',
        ...(queryOptions?.(params, new URL(request.url)) || {}),
      }
    );

    return preventTransitionBeforeLoaded
      ? preloadedQueryRef.toPromise()
      : preloadedQueryRef;
  };

  const PreloadedComponent: ComponentType<
    P & { preloadedQueryRef: QueryReference<TData, TVariables> }
  > = ({ preloadedQueryRef, ...otherProps }) => {
    const queryResult = useReadQuery<TData>(preloadedQueryRef);
    return <Component {...(otherProps as P)} queryResult={queryResult} />;
  };

  const ComponentWithRouteQuery = (props: P) => {
    const preloadedQueryRef = useLoaderData() as QueryReference<
      TData,
      TVariables
    > | null;

    if (!preloadedQueryRef) {
      return null;
    }
    const preloadedComponent = (
      <PreloadedComponent {...props} preloadedQueryRef={preloadedQueryRef} />
    );
    if (options.preventTransitionBeforeLoaded) {
      return preloadedComponent;
    }
    const { FallbackComponent = DefaultFallback } = options;

    return (
      <Suspense fallback={<FallbackComponent />}>{preloadedComponent}</Suspense>
    );
  };

  return Object.assign(ComponentWithRouteQuery, {
    loader,
    shouldRevalidate: queryOptions
      ? shouldRevalidateFn(
          (params, url) => queryOptions(params, url).variables!
        )
      : undefined,
  });
};
