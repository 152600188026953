import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  Location,
  Navigate,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import Button from 'atoms/buttons/Button';
import IconButton from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import StarBall from 'atoms/icons/StarBall';
import { Horizontal } from 'atoms/layout/flex';
import { LabelL } from 'atoms/typography';
import {
  FOOTBALL_PLAY_RIVALS,
  LANDING,
  RIVALS,
} from 'constants/__generated__/routes';
import { LANDING_BY_SPORT, catchAll } from 'constants/routes';
import { useConnectionContext } from 'contexts/connection';
import { useIntlContext } from 'contexts/intl';
import useScreenSize from 'hooks/device/useScreenSize';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useEvents from 'lib/events/useEvents';
import { glossary } from 'lib/glossary';
import { absCenter } from 'lib/style';
import { desktopAndAbove, laptopAndAbove } from 'style/mediaQuery';

import { SportContainer } from './SportContainer';
import nbaBg from './assets/DonovanMitchel.jpg';
import mlbBg from './assets/JulioRodriguez.jpg';
import footballBg from './assets/football.jpg';

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: grid;
  overflow: hidden;
  position: relative;
  grid-template-rows: 1fr 1fr 1fr;
  transition: 500ms;

  @media ${laptopAndAbove} {
    &:not(.step2) {
      aspect-ratio: 1440 / 810;
    }
  }

  @media ${desktopAndAbove} {
    max-height: 100vh;
    aspect-ratio: 1440 / 810;
    min-height: min(780px, 100vh);
  }

  &:not(.step0) {
    &.FOOTBALL {
      grid-template-rows: 3fr 0fr 0fr;
    }
    &.NBA {
      grid-template-rows: 0fr 3fr 0fr;
    }
    &.BASEBALL {
      grid-template-rows: 0fr 0fr 3fr;
    }
  }

  @media ${laptopAndAbove} {
    grid-auto-flow: column;
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr 1fr;

    &:not(.step0) {
      &.FOOTBALL {
        grid-template-rows: none;
        grid-template-columns: 3fr 0fr 0fr;
      }
      &.NBA {
        grid-template-rows: none;
        grid-template-columns: 0fr 3fr 0fr;
      }
      &.BASEBALL {
        grid-template-rows: none;
        grid-template-columns: 0fr 0fr 3fr;
      }
    }
  }
`;

const CloseButtonWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: var(--double-unit);
  padding: 0 var(--double-unit);
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled(animated.h1)`
  ${absCenter}
  z-index: 1;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  letter-spacing: -1px;
  width: 100%;
  padding: 0 var(--unit);
  @media ${laptopAndAbove} {
    font-size: 48px;
  }
`;

const SmallStarBall = styled(StarBall)`
  height: 26px;
`;

const messages = defineMessages({
  title: {
    id: 'Landing.chooseYourSportModal.title',
    defaultMessage: 'Put your manager skills to the test',
  },
  football: {
    id: 'Landing.chooseYourSportModal.football',
    defaultMessage: 'Start with Football',
  },
  footballSecondaryStep: {
    id: 'Landing.chooseYourSportModal.footballSecondaryStep',
    defaultMessage: 'Choose your Football Manager name',
  },
  footballSecondaryStepSub: {
    id: 'Landing.chooseYourSportModal.footballSecondaryStepSub',
    defaultMessage: 'Pick a name the world of football will fear.',
  },
  nba: {
    id: 'Landing.chooseYourSportModal.nba',
    defaultMessage: 'Start with Basketball',
  },
  nbaSecondaryStep: {
    id: 'Landing.chooseYourSportModal.nbaSecondaryStep',
    defaultMessage: 'Choose your NBA Manager name',
  },
  nbaSecondaryStepSub: {
    id: 'Landing.chooseYourSportModal.nbaSecondaryStepSub',
    defaultMessage: 'Pick a name the world of basketball will fear.',
  },
  baseball: {
    id: 'Landing.chooseYourSportModal.baseball',
    defaultMessage: 'Start with Baseball',
  },
  mlbSecondaryStep: {
    id: 'Landing.chooseYourSportModal.mlbSecondaryStep',
    defaultMessage: 'Choose your MLB Manager name',
  },
  mlbSecondaryStepSub: {
    id: 'Landing.chooseYourSportModal.mlbSecondaryStepSub',
    defaultMessage: 'Pick a name the world of baseball will fear.',
  },
});

const getLocationState = (
  sport: Sport,
  location: Location,
  fromRivals?: boolean
) => {
  // eslint-disable-next-line prefer-const
  let { afterLoggedInTarget, ...state } = location.state || {};
  if (sport === Sport.NBA) {
    afterLoggedInTarget = LANDING_BY_SPORT.NBA;
  } else if (sport === Sport.BASEBALL) {
    afterLoggedInTarget = LANDING_BY_SPORT.BASEBALL;
  } else if (fromRivals) {
    afterLoggedInTarget = FOOTBALL_PLAY_RIVALS;
  } else {
    afterLoggedInTarget = LANDING_BY_SPORT.FOOTBALL;
  }
  return {
    afterLoggedInTarget,
    ...state,
  };
};
const useActiveSport = () => {
  const location = useLocation();
  const onNba = !!useMatch(catchAll(LANDING_BY_SPORT[Sport.NBA]));
  const isFootballLanding = !!useMatch(
    catchAll(LANDING_BY_SPORT[Sport.FOOTBALL])
  );
  const isLanding = !!useMatch(LANDING);
  const isRivalsLanding = !!useMatch(catchAll(RIVALS));
  const onFootball = isLanding || isFootballLanding || isRivalsLanding;
  const onBaseball = !!useMatch(catchAll(LANDING_BY_SPORT[Sport.BASEBALL]));
  const currentSport =
    (onNba && Sport.NBA) ||
    (onFootball && Sport.FOOTBALL) ||
    (onBaseball && Sport.BASEBALL) ||
    undefined;
  const [state, setState] = useState<Sport | undefined>(currentSport);

  return {
    activeSport: state,
    setActiveSport: setState,
    getAfterLoggedInTarget: (sport: Sport) =>
      getLocationState(sport, location, isRivalsLanding),
  };
};

type Props = {
  onClose: () => void;
  WalletPortal: ReactNode;
};

export const ChooseYourSport = ({ onClose, WalletPortal }: Props) => {
  const { formatMessage } = useIntlContext();
  const { up: isLaptopAndAbove } = useScreenSize('laptop');
  const location = useLocation();

  const [step, setStep] = useState<0 | 1 | 2>(0);

  const { activeSport, setActiveSport, getAfterLoggedInTarget } =
    useActiveSport();
  const { signIn } = useConnectionContext();
  const track = useEvents();
  const { isMobileApp } = useIsMobileApp();
  const navigate = useNavigate();

  const handleClose = () => {
    switch (step) {
      case 1:
        setStep(0);
        setActiveSport(undefined);
        break;
      case 2:
        setStep(1);
        break;
      default:
        onClose();
    }
  };

  const transitions = useTransition(step === 0, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  const handleSportClick = (sport: Sport) => {
    setStep(1);
    setActiveSport(sport);
    // store the sport in the location state so we can redirect to the correct sport home page after signup
    navigate(location, {
      state: getAfterLoggedInTarget(sport),
      replace: true,
    });
    track('Choose Signup Sport', { sport });
  };
  const defaultProps = {
    step,
    WalletPortal,
    goToNextStep: () => setStep(2),
  };

  if (activeSport && step === 0) {
    setStep(1);
    return (
      <Navigate
        to={location}
        state={getAfterLoggedInTarget(activeSport)}
        replace
      />
    );
  }

  return (
    <Wrapper className={classNames(activeSport, `step${step}`)}>
      <CloseButtonWrapper>
        <div>
          {(step > 0 || !isMobileApp) && (
            <IconButton onClick={handleClose} disableDebounce color="opa">
              <FontAwesomeIcon
                size="2x"
                icon={step === 0 ? faXmark : faArrowLeft}
                color="white"
              />
            </IconButton>
          )}
        </div>
        <Horizontal gap={0.5}>
          {isLaptopAndAbove && (
            <LabelL as="span" color="var(--c-neutral-900)">
              <FormattedMessage
                id="LandingMultiSport.signIn.label"
                defaultMessage="Have an account?"
              />
            </LabelL>
          )}
          <Button size="small" color="secondary" onClick={() => signIn()}>
            <FormattedMessage {...glossary.signin} />
          </Button>
        </Horizontal>
      </CloseButtonWrapper>
      {isLaptopAndAbove &&
        transitions(
          (styles, item) =>
            item && (
              <Title style={styles}>
                {formatMessage(messages.title)}
                <SmallStarBall />
              </Title>
            )
        )}
      <SportContainer
        {...defaultProps}
        sport={Sport.FOOTBALL}
        background={footballBg}
        active={activeSport === Sport.FOOTBALL}
        onClick={() => handleSportClick(Sport.FOOTBALL)}
        startButtonText={formatMessage(messages.football)}
        secondaryStepTitle={formatMessage(messages.footballSecondaryStep)}
        secondaryStepSubtitle={formatMessage(messages.footballSecondaryStepSub)}
      />
      <SportContainer
        {...defaultProps}
        sport={Sport.NBA}
        background={nbaBg}
        active={activeSport === Sport.NBA}
        onClick={() => handleSportClick(Sport.NBA)}
        startButtonText={formatMessage(messages.nba)}
        secondaryStepTitle={formatMessage(messages.nbaSecondaryStep)}
        secondaryStepSubtitle={formatMessage(messages.nbaSecondaryStepSub)}
      />
      <SportContainer
        {...defaultProps}
        sport={Sport.BASEBALL}
        background={mlbBg}
        active={activeSport === Sport.BASEBALL}
        onClick={() => handleSportClick(Sport.BASEBALL)}
        startButtonText={formatMessage(messages.baseball)}
        secondaryStepTitle={formatMessage(messages.mlbSecondaryStep)}
        secondaryStepSubtitle={formatMessage(messages.mlbSecondaryStepSub)}
      />
    </Wrapper>
  );
};
